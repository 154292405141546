<template>
  <div class="wrapper">
    <!--sidebar wrapper -->
    <div class="sidebar-wrapper" data-simplebar="true">
      <div class="sidebar-header">
        <div>
          <h4 class="logo-text">LINK</h4>
        </div>
        <div class="toggle-icon ms-auto">
          <i class="bx bx-arrow-to-left"></i>
        </div>
      </div>

      <!--navigation-->
      <ul class="metismenu" id="menu">

                <router-link v-if="!$hasRole('Customer')" custom to="/p" v-slot="{href, navigate, isExactActive}">
                    <li :class="[isExactActive && 'mm-active']">
                        <a :href="href" @click="navigate">
                            <div class="parent-icon"><i class="bx bx-home-circle"></i></div>
                            <div class="menu-title">Home</div>
                        </a>
                    </li>
                </router-link>

<!--        <router-link custom to="/p/schedule" v-slot="{href, navigate, isExactActive}">-->
<!--          <li :class="[isExactActive && 'mm-active']">-->
<!--            <a :href="href" @click="navigate">-->
<!--              <div class="parent-icon"><i class='bx bxs-calendar'></i></div>-->
<!--              <div class="menu-title">Schedule</div>-->
<!--            </a>-->
<!--          </li>-->
<!--        </router-link>-->

                    <router-link v-if="$hasOneOfRoles('Support', 'Admin', 'Senior Engineer')" custom to="/p/jobs" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class="bx bxs-receipt"></i></div>
                                <div class="menu-title">Jobs</div>
                            </a>
                        </li>
                    </router-link>
                    <router-link v-if="$hasOneOfRoles('Support', 'Admin', 'Senior Engineer')" custom to="/p/nearest" v-slot="{href, navigate, isExactActive}">
                      <li :class="[isExactActive && 'mm-active']">
                          <a :href="href" @click="navigate">
                              <div class="parent-icon"><i class="bx bxs-receipt"></i></div>
                              <div class="menu-title">Nearest Jobs</div>
                          </a>
                      </li>
                  </router-link>

          <li v-if="$hasOneOfRoles('Support', 'Admin')">
            <a class="has-arrow" href="javascript:;" aria-expanded="false">
              <div class="parent-icon"><i class='bx bxs-report'></i></div>
              <div class="menu-title">Reports</div>
            </a>
            <ul class="mm-collapse">
              <router-link v-if="$hasOneOfRoles('Support', 'Admin')" custom to="/p/reports/jobsheets" v-slot="{href, navigate, isExactActive}">
                <li :class="[isExactActive && 'mm-active']">
                  <a :href="href" @click="navigate">
                    <div class="menu-title">Jobsheets</div>
                  </a>
                </li>
              </router-link>
              <router-link v-if="$hasOneOfRoles('Support')" custom to="/p/reports/faultysites" v-slot="{href, navigate, isExactActive}">
                <li :class="[isExactActive && 'mm-active']">
                  <a :href="href" @click="navigate">
                    <div class="menu-title">Faulty Sites</div>
                  </a>
                </li>
              </router-link>
              <router-link v-if="$hasOneOfRoles('Support')" custom to="/p/reports/completedjobs" v-slot="{href, navigate, isExactActive}">
                <li :class="[isExactActive && 'mm-active']">
                  <a :href="href" @click="navigate">
                    <div class="menu-title">Completed Jobs</div>
                  </a>
                </li>
              </router-link>
            </ul>
          </li>

                    <router-link v-if="$hasOneOfRoles('Support', 'Admin', 'Senior Engineer')" custom to="/p/sites" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class='bx bxs-map-pin'></i></div>
                                <div class="menu-title">Sites</div>
                            </a>
                        </li>
                    </router-link>

                    <router-link v-if="$hasOneOfRoles('Support', 'Admin')" custom to="/p/clients" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class='bx bxs-business'></i></div>
                                <div class="menu-title">Clients</div>
                            </a>
                        </li>
                    </router-link>

          <router-link v-if="$hasOneOfRoles('Support', 'Admin')" custom to="/p/brands" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class='bx bx-images' ></i></div>
                                <div class="menu-title">Brands</div>
                            </a>
                        </li>
                    </router-link>

          <router-link v-if="$hasOneOfRoles('Admin', 'Service')" custom to="/p/vehicles" v-slot="{href, navigate, isExactActive}">
            <li :class="[isExactActive && 'mm-active']">
              <a :href="href" @click="navigate">
                <div class="parent-icon"><i class='bx bxs-car'></i></div>
                <div class="menu-title">Vehicles</div>
              </a>
            </li>
          </router-link>

          <router-link v-if="$hasOneOfRoles('Admin', 'Service', 'Support')" custom to="/p/map" v-slot="{href, navigate, isExactActive}">
            <li :class="[isExactActive && 'mm-active']">
              <a :href="href" @click="navigate">
                <div class="parent-icon"><i class='bx bx-map'></i></div>
                <div class="menu-title">Map</div>
              </a>
            </li>
          </router-link>

          <router-link v-if="$hasOneOfRoles('Admin', 'Service', 'Support')" custom to="/p/schedule" v-slot="{href, navigate, isExactActive}">
            <li :class="[isExactActive && 'mm-active']">
              <a :href="href" @click="navigate">
                <div class="parent-icon"><i class='bx bx-calendar'></i></div>
                <div class="menu-title">Schedule</div>
              </a>
            </li>
          </router-link>

          <router-link v-if="$hasOneOfRoles('Admin')" custom to="/p/products" v-slot="{href, navigate, isExactActive}">
            <li :class="[isExactActive && 'mm-active']">
              <a :href="href" @click="navigate">
                <div class="parent-icon"><i class='bx bx-box'></i></div>
                <div class="menu-title">Products</div>
              </a>
            </li>
          </router-link>

          <router-link v-if="$hasOneOfRoles('Admin')" custom to="/p/companies" v-slot="{href, navigate, isExactActive}">
            <li :class="[isExactActive && 'mm-active']">
              <a :href="href" @click="navigate">
                <div class="parent-icon"><i class='bx bx-building'></i></div>
                <div class="menu-title">Companies</div>
              </a>
            </li>
          </router-link>

          <router-link v-if="$hasOneOfRoles('Admin', 'Support')" custom to="/p/checklist" v-slot="{href, navigate, isExactActive}">
            <li :class="[isExactActive && 'mm-active']">
              <a :href="href" @click="navigate">
                <div class="parent-icon"><i class='bx bx-check-square'></i></div>
                <div class="menu-title">Checklist</div>
              </a>
            </li>
          </router-link>

          <router-link custom to="/p/changelog" v-slot="{href, navigate, isExactActive}">
            <li :class="[isExactActive && 'mm-active']">
              <a :href="href" @click="navigate">
                <div class="parent-icon"><i class='bx bx-time'></i></div>
                <div class="menu-title">Change Log</div>
              </a>
            </li>
          </router-link>

                    <li v-if="$hasOneOfRoles('Admin')" class="menu-label">User Management</li>

                    <router-link v-if="$hasOneOfRoles('Admin')" custom to="/p/management/users" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class='bx bx-user' ></i></div>
                                <div class="menu-title">Users</div>
                            </a>
                        </li>
                    </router-link>

                    <router-link v-if="$hasOneOfRoles('Admin')" custom to="/p/management/roles" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class='bx bxs-user-badge' ></i></div>
                                <div class="menu-title">Roles</div>
                            </a>
                        </li>
                    </router-link>

                    <router-link v-if="$hasOneOfRoles('Admin')" custom to="/p/management/permissions" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class='bx bxs-lock' ></i></div>
                                <div class="menu-title">Permissions</div>
                            </a>
                        </li>
                    </router-link>

          <router-link v-if="$hasOneOfRoles('Admin')" custom to="/p/management/permissiongroups" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class='bx bxs-lock' ></i></div>
                                <div class="menu-title">Permission Groups</div>
                            </a>
                        </li>
                    </router-link>

          <!-- CUSTOMER ROUTING -->
          <router-link v-if="$hasOneOfRoles('Customer')" custom to="/p/my-jobs" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class="bx bxs-receipt"></i></div>
                                <div class="menu-title">My Jobs</div>
                            </a>
                        </li>
                    </router-link>

          <router-link v-if="$hasOneOfRoles('Customer')" custom to="/p/my-companies" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'mm-active']">
                            <a :href="href" @click="navigate">
                                <div class="parent-icon"><i class='bx bxs-business'></i></div>
                                <div class="menu-title">My Companies</div>
                            </a>
                        </li>
                    </router-link>

        <li class="menu-label">Version {{this.version}}</li>

      </ul>
      <!--end navigation-->
    </div>
    <!--end sidebar wrapper -->
    <!--start header -->
    <header>
      <div class="topbar d-flex align-items-center">
        <nav class="navbar navbar-expand">
          <div class="mobile-toggle-menu"><i class="bx bx-menu"></i></div>
<!--          <div class="search-bar flex-grow-1">-->
<!--            <div class="position-relative search-bar-box">-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control search-control"-->
<!--                placeholder="Type to search..."-->
<!--              />-->
<!--              <span-->
<!--                class="position-absolute top-50 search-show translate-middle-y"-->
<!--                ><i class="bx bx-search"></i-->
<!--              ></span>-->
<!--              <span-->
<!--                class="position-absolute top-50 search-close translate-middle-y"-->
<!--                ><i class="bx bx-x"></i-->
<!--              ></span>-->
<!--            </div>-->
<!--          </div>-->
          <div class="top-menu ms-auto">
            <ul class="navbar-nav align-items-center">
              <li class="nav-item mobile-search-icon">
                <a class="nav-link" href="#"> <i class="bx bx-search"></i> </a>
              </li>
              <li v-if="!$hasRole('Customer')" class="nav-item dropdown dropdown-large">
                <a
                  class="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bx bx-category"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <div class="row row-cols-3 g-3 p-3">
                    <div class="col text-center" @click="newJob">
                      <div class="app-box mx-auto">
                        <i class="bx bxs-receipt"></i>
                      </div>
                      <div class="app-title">New Job</div>
                    </div>
                    <div class="col text-center" @click="newSite">
                      <div class="app-box mx-auto">
                        <i class="bx bxs-map-pin"></i>
                      </div>
                      <div class="app-title">New Site</div>
                    </div>
                  </div>
                </div>
              </li>
<!--              <li class="nav-item dropdown dropdown-large">-->
<!--                <a-->
<!--                  class="-->
<!--                    nav-link-->
<!--                    dropdown-toggle dropdown-toggle-nocaret-->
<!--                    position-relative-->
<!--                  "-->
<!--                  href="#"-->
<!--                  role="button"-->
<!--                  data-bs-toggle="dropdown"-->
<!--                  aria-expanded="false"-->
<!--                >-->
<!--                  <span class="alert-count">7</span>-->
<!--                  <i class="bx bx-bell"></i>-->
<!--                </a>-->
<!--                <div class="dropdown-menu dropdown-menu-end">-->
<!--                  <a href="javascript:;">-->
<!--                    <div class="msg-header">-->
<!--                      <p class="msg-header-title">Notifications</p>-->
<!--                      <p class="msg-header-clear ms-auto">Marks all as read</p>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                  <div class="header-notifications-list">-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="notify"><i class="bx bx-group"></i></div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            New Customers<span class="msg-time float-end"-->
<!--                              >14 Sec ago</span-->
<!--                            >-->
<!--                          </h6>-->
<!--                          <p class="msg-info">5 new user registered</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="notify"><i class="bx bx-cart-alt"></i></div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            New Orders-->
<!--                            <span class="msg-time float-end">2 min ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">You have recived new orders</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="notify"><i class="bx bx-file"></i></div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            24 PDF File<span class="msg-time float-end"-->
<!--                              >19 min ago</span-->
<!--                            >-->
<!--                          </h6>-->
<!--                          <p class="msg-info">The pdf files generated</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="notify"><i class="bx bx-send"></i></div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Time Response-->
<!--                            <span class="msg-time float-end">28 min ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">5.1 min avarage time response</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="notify">-->
<!--                          <i class="bx bx-home-circle"></i>-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            New Product Approved-->
<!--                            <span class="msg-time float-end">2 hrs ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">Your new product has approved</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="notify">-->
<!--                          <i class="bx bx-message-detail"></i>-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            New Comments-->
<!--                            <span class="msg-time float-end">4 hrs ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">New customer comments recived</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="notify">-->
<!--                          <i class="bx bx-check-square"></i>-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Your item is shipped-->
<!--                            <span class="msg-time float-end">5 hrs ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">Successfully shipped your item</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="notify"><i class="bx bx-user-pin"></i></div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            New 24 authors<span class="msg-time float-end"-->
<!--                              >1 day ago</span-->
<!--                            >-->
<!--                          </h6>-->
<!--                          <p class="msg-info">-->
<!--                            24 new authors joined last week-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="notify">-->
<!--                          <i class="bx bx-door-open"></i>-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Defense Alerts-->
<!--                            <span class="msg-time float-end">2 weeks ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">45% less alerts last 4 weeks</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                  <a href="javascript:;">-->
<!--                    <div class="text-center msg-footer">-->
<!--                      View All Notifications-->
<!--                    </div>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li class="nav-item dropdown dropdown-large">-->
<!--                <a-->
<!--                  class="-->
<!--                    nav-link-->
<!--                    dropdown-toggle dropdown-toggle-nocaret-->
<!--                    position-relative-->
<!--                  "-->
<!--                  href="#"-->
<!--                  role="button"-->
<!--                  data-bs-toggle="dropdown"-->
<!--                  aria-expanded="false"-->
<!--                >-->
<!--                  <span class="alert-count">8</span>-->
<!--                  <i class="bx bx-comment"></i>-->
<!--                </a>-->
<!--                <div class="dropdown-menu dropdown-menu-end">-->
<!--                  <a href="javascript:;">-->
<!--                    <div class="msg-header">-->
<!--                      <p class="msg-header-title">Messages</p>-->
<!--                      <p class="msg-header-clear ms-auto">Marks all as read</p>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                  <div class="header-message-list">-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-1.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Daisy Anderson-->
<!--                            <span class="msg-time float-end">5 sec ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">The standard chunk of lorem</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-2.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Althea Cabardo-->
<!--                            <span class="msg-time float-end">14 sec ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">-->
<!--                            Many desktop publishing packages-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-3.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Oscar Garner-->
<!--                            <span class="msg-time float-end">8 min ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">-->
<!--                            Various versions have evolved over-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-4.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Katherine Pechon-->
<!--                            <span class="msg-time float-end">15 min ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">-->
<!--                            Making this the first true generator-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-5.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Amelia Doe-->
<!--                            <span class="msg-time float-end">22 min ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">-->
<!--                            Duis aute irure dolor in reprehenderit-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-6.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Cristina Jhons-->
<!--                            <span class="msg-time float-end">2 hrs ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">-->
<!--                            The passage is attributed to an unknown-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-7.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            James Caviness-->
<!--                            <span class="msg-time float-end">4 hrs ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">The point of using Lorem</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-8.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Peter Costanzo-->
<!--                            <span class="msg-time float-end">6 hrs ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">-->
<!--                            It was popularised in the 1960s-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-9.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            David Buckley-->
<!--                            <span class="msg-time float-end">2 hrs ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">-->
<!--                            Various versions have evolved over-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-10.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Thomas Wheeler-->
<!--                            <span class="msg-time float-end">2 days ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">-->
<!--                            If you are going to use a passage-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                    <a class="dropdown-item" href="javascript:;">-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <div class="user-online">-->
<!--                          <img-->
<!--                            src="/assets/images/avatars/avatar-11.png"-->
<!--                            class="msg-avatar"-->
<!--                            alt="user avatar"-->
<!--                          />-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                          <h6 class="msg-name">-->
<!--                            Johnny Seitz-->
<!--                            <span class="msg-time float-end">5 days ago</span>-->
<!--                          </h6>-->
<!--                          <p class="msg-info">All the Lorem Ipsum generators</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                  <a href="javascript:;">-->
<!--                    <div class="text-center msg-footer">View All Messages</div>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </li>-->
            </ul>
          </div>
          <div class="user-box dropdown">
            <a
              class="
                d-flex
                align-items-center
                nav-link
                dropdown-toggle dropdown-toggle-nocaret
              "
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
             <img v-if="user.email === 'ad@ads-europe.co.uk'"
               src="/assets/images/greggs.jpeg"
               class="user-img"
               alt="user avatar"
             />
              <div class="user-info ps-3">
                <p class="user-name mb-0">{{user.fname}} {{user.lname}}</p>
                <p class="designattion mb-0" v-if="user.job_title">{{user.job_title}}</p>
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
<!--              <li>-->
<!--                <a class="dropdown-item" href="javascript:;"-->
<!--                  ><i class="bx bx-user"></i><span>Profile</span></a-->
<!--                >-->
<!--              </li>-->
<!--              <li>-->
<!--                <a class="dropdown-item" href="javascript:;"-->
<!--                  ><i class="bx bx-cog"></i><span>Settings</span></a-->
<!--                >-->
<!--              </li>-->
<!--              <li>-->
<!--                <a class="dropdown-item" href="javascript:;"-->
<!--                  ><i class="bx bx-home-circle"></i><span>Dashboard</span></a-->
<!--                >-->
<!--              </li>-->
<!--              <li>-->
<!--                <a class="dropdown-item" href="javascript:;"-->
<!--                  ><i class="bx bx-dollar-circle"></i><span>Earnings</span></a-->
<!--                >-->
<!--              </li>-->
<!--              <li>-->
<!--                <a class="dropdown-item" href="javascript:;"-->
<!--                  ><i class="bx bx-download"></i><span>Downloads</span></a-->
<!--                >-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="dropdown-divider mb-0"></div>-->
<!--              </li>-->
              <li>
                <a class="dropdown-item" href="javascript:;" @click="logout"
                  ><i class="bx bx-log-out-circle"></i><span>Logout</span></a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
    <!--end header -->
    <!--start page wrapper -->
    <div class="page-wrapper">
      <div class="page-content"> 
          <!-- <div class="snowflakes" v-if="user.email === 'ad@ads-europe.co.uk'" aria-hidden="true">
            <div v-for="i in 3" :key="'greggsflake-' + i" class="snowflake">
              <img src="/assets/images/greggs.jpeg" width="30" height="30">
            </div>
            <div v-for="j in 2" :key="'kfcflake-' + j" class="snowflake">
              <img src="/assets/images/kfc.webp" width="30" height="30">
            </div>
            <div v-for="k in 3" :key="'macciesflake-' + k" class="snowflake">
              <img src="/assets/images/mcdonalds.jpeg" width="30" height="30">
            </div>
            <div v-for="l in 2" :key="'burgerkingflake-' + l" class="snowflake">
              <img src="/assets/images/burgerking.png" width="30" height="30">
            </div>
          </div> -->
                <!--breadcrumb-->
                <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div class="breadcrumb-title pe-3">{{$route.meta.name}}</div>
                    <div class="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item"><a href="javascript:;" @click="$router.push('/p')"><i class="bx bx-home-alt"></i></a></li>
                                <li v-for="crumb in $route.meta.breadcrumb" :key="crumb.id" class="breadcrumb-item"><a href="javascript:;" @click="$router.push(crumb.route)">{{crumb.name}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{$route.meta.name}}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <!--end breadcrumb-->
        <router-view></router-view>
      </div>
    </div>

    <new-site-form v-if="!$hasRole('Customer')" id="layoutNewSiteForm" ref="layoutNewSiteForm"></new-site-form>
    <new-job-form v-if="!$hasRole('Customer')" id="layoutNewJobForm" ref="layoutNewJobForm"></new-job-form>
    <!--end page wrapper -->
    <!--start overlay-->
    <div class="overlay toggle-icon"></div>
    <!--end overlay-->
    <!--Start Back To Top Button-->
    <a href="javaScript:;" class="back-to-top"
      ><i class="bx bxs-up-arrow-alt"></i
    ></a>
    <!--End Back To Top Button-->
    <footer class="page-footer">
      <p class="mb-0">Copyright © AD Systems {{new Date().getFullYear()}}. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NewSiteForm from "./sites/NewSiteForm";
import NewJobForm from "./jobs/NewJobForm";
/* global $ */
export default {
  name: "Layout",
  components: {NewJobForm, NewSiteForm},
  data(){
        return {
            version: process.env.VUE_APP_VERSION || "0.0.1"
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            getModule: 'system/getModule',
            getModules: 'system/getModules'
        }),
        module: {
            get() {
                return this.getModule
            },
            set (value) {
                this.setModule(value);
            }
        },
    allowedModules: function(){
      let modules = [
        "Support",
        "Admin",
        "Sales",
        "Service"
      ];
      let allowed = [];
      modules.forEach((module) => {
        if(this.$hasRole(module)){
          allowed.push(module);
        }
      });
      return allowed;
    }
    },
    methods: {
        ...mapActions({
      setModule: "system/setModule",
      setUser: "auth/setUser",
      setToken: "auth/setToken"
    }),
    logout(){
      localStorage.removeItem('link::token');
      window.location.reload();
    },
    newSite(){
      this.$refs.layoutNewSiteForm.show();
    },
    newJob(){
      this.$refs.layoutNewJobForm.show();
    }
    },
  mounted() {
        $('.single-select').select2({
                theme: 'bootstrap4',
                width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
                placeholder: $(this).data('placeholder'),
                allowClear: Boolean($(this).data('allow-clear')),
                dropdownParent: $('#moduleDropdown')
            });
    $(function() {

            $(".knob").knob();

            $(".mobile-search-icon").on("click", function() {
                $(".search-bar").addClass("full-search-bar")
            }), $(".search-close").on("click", function() {
                $(".search-bar").removeClass("full-search-bar")
            }), $(".mobile-toggle-menu").on("click", function() {
                $(".wrapper").addClass("toggled")
            }), $(".toggle-icon").click(function() {
                $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function() {
                    $(".wrapper").addClass("sidebar-hovered")
                }, function() {
                    $(".wrapper").removeClass("sidebar-hovered")
                }))
            }), $(document).ready(function() {
                $(window).on("scroll", function() {
                    $(this).scrollTop() > 300 ? $(".back-to-top").fadeIn() : $(".back-to-top").fadeOut()
                }), $(".back-to-top").on("click", function() {
                    return $("html, body").animate({
                        scrollTop: 0
                    }, 600), !1
                })
            }),

            $(document).ready(function () {
                    $(window).on("scroll", function () {
                        if ($(this).scrollTop() > 60) {
                            $('.topbar').addClass('bg-dark');
                        } else {
                            $('.topbar').removeClass('bg-dark');
                        }
                    });
                    $('.back-to-top').on("click", function () {
                        $("html, body").animate({
                            scrollTop: 0
                        }, 600);
                        return false;
                    });
                });


            $(function() {
                for (var e = window.location, o = $(".metismenu li a").filter(function() {
                        return this.href == e
                    }).addClass("").parent().addClass("mm-active"); o.is("li");) o = o.parent("").addClass("mm-show").parent("").addClass("mm-active")
            }), $(function() {
                $("#menu").metisMenu()
            }), $(".chat-toggle-btn").on("click", function() {
                $(".chat-wrapper").toggleClass("chat-toggled")
            }), $(".chat-toggle-btn-mobile").on("click", function() {
                $(".chat-wrapper").removeClass("chat-toggled")
            }), $(".email-toggle-btn").on("click", function() {
                $(".email-wrapper").toggleClass("email-toggled")
            }), $(".email-toggle-btn-mobile").on("click", function() {
                $(".email-wrapper").removeClass("email-toggled")
            }), $(".compose-mail-btn").on("click", function() {
                $(".compose-mail-popup").show()
            }), $(".compose-mail-close").on("click", function() {
                $(".compose-mail-popup").hide()
            }),
            
            
            $(".switcher-btn").on("click", function() {
                $(".switcher-wrapper").toggleClass("switcher-toggled")
            }), $(".close-switcher").on("click", function() {
                $(".switcher-wrapper").removeClass("switcher-toggled")
            }),


            $('#theme1').click(theme1);
                $('#theme2').click(theme2);
                $('#theme3').click(theme3);
                $('#theme4').click(theme4);
                $('#theme5').click(theme5);
                $('#theme6').click(theme6);
                $('#theme7').click(theme7);
                $('#theme8').click(theme8);
                $('#theme9').click(theme9);
                $('#theme10').click(theme10);
                $('#theme11').click(theme11);
                $('#theme12').click(theme12);
                $('#theme13').click(theme13);
                $('#theme14').click(theme14);
                $('#theme15').click(theme15);

                function theme1() {
                    $('body').attr('class', 'bg-theme bg-theme1');
                }

                function theme2() {
                    $('body').attr('class', 'bg-theme bg-theme2');
                }

                function theme3() {
                    $('body').attr('class', 'bg-theme bg-theme3');
                }

                function theme4() {
                    $('body').attr('class', 'bg-theme bg-theme4');
                }
            
            function theme5() {
                    $('body').attr('class', 'bg-theme bg-theme5');
                }
            
            function theme6() {
                    $('body').attr('class', 'bg-theme bg-theme6');
                }

                function theme7() {
                    $('body').attr('class', 'bg-theme bg-theme7');
                }

                function theme8() {
                    $('body').attr('class', 'bg-theme bg-theme8');
                }

                function theme9() {
                    $('body').attr('class', 'bg-theme bg-theme9');
                }

                function theme10() {
                    $('body').attr('class', 'bg-theme bg-theme10');
                }

                function theme11() {
                    $('body').attr('class', 'bg-theme bg-theme11');
                }

                function theme12() {
                    $('body').attr('class', 'bg-theme bg-theme12');
                }

            function theme13() {
                $('body').attr('class', 'bg-theme bg-theme13');
                }
                
                function theme14() {
                $('body').attr('class', 'bg-theme bg-theme14');
                }
                
                function theme15() {
                $('body').attr('class', 'bg-theme bg-theme15');
                }



        });
    }
};
</script>

<style scoped>

  .wrapper .toggled > #moduleDropdown {
    display: none;
  }

  .wrapper .toggled .sidebar-hovered > #moduleDropdown {
    display: block !important;
  }

  .snowflake {
    color: #fff;
    font-size: 1em;
    font-family: Arial;
    text-shadow: 0 0 1px #000;
  }

  @-webkit-keyframes snowflakes-fall{
    0%{
      top:-10%
    }
    100%{
      top:100%
    }
  }
  @-webkit-keyframes snowflakes-shake{
    0%{
      -webkit-transform:translateX(0px);
      transform:translateX(0px)
    }
    50%{
      -webkit-transform:translateX(80px);
      transform:translateX(80px)
    }
    100%{
      -webkit-transform:translateX(0px);
      transform:translateX(0px)
    }
  }
  
  @keyframes snowflakes-fall{
    0%{top:-10%}
    100%{top:100%}
  }
  @keyframes snowflakes-shake{
    0%{
      transform:translateX(0px)
    }
    50%{
      transform:translateX(80px)
    }
    100%{
      transform:translateX(0px)
    }
  }
  
  .snowflake{
    position:fixed;
    top:-10%;
    z-index:9999;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:default;
    -webkit-animation-name:snowflakes-fall,snowflakes-shake;
    -webkit-animation-duration:10s,3s;
    -webkit-animation-timing-function:linear,ease-in-out;
    -webkit-animation-iteration-count:infinite,infinite;
    -webkit-animation-play-state:running,running;
    animation-name:snowflakes-fall,snowflakes-shake;
    animation-duration:10s,3s;
    animation-timing-function:linear,ease-in-out;
    animation-iteration-count:infinite,infinite;
    animation-play-state:running,running
  }
  
  .snowflake:nth-of-type(0){
    left:1%;-webkit-animation-delay:0s,0s;
    animation-delay:0s,0s
  }
  .snowflake:nth-of-type(1){
    left:10%;-webkit-animation-delay:1s,1s;
    animation-delay:1s,1s
  }
  
  .snowflake:nth-of-type(2){
    left:20%;
    -webkit-animation-delay:6s,.5s;
    animation-delay:6s,.5s
  }
  
  .snowflake:nth-of-type(3){
    left:30%;
    -webkit-animation-delay:4s,2s;
    animation-delay:4s,2s
  }
  
  .snowflake:nth-of-type(4){
    left:40%;
    -webkit-animation-delay:2s,2s;
    animation-delay:2s,2s
  }
  .snowflake:nth-of-type(5){
    left:50%;
    -webkit-animation-delay:8s,3s;
    animation-delay:8s,3s
  }
  
  .snowflake:nth-of-type(6){
    left:60%;
    -webkit-animation-delay:6s,2s;
    animation-delay:6s,2s
  }
  .snowflake:nth-of-type(7){
    left:70%;
    -webkit-animation-delay:2.5s,1s;
    animation-delay:2.5s,1s
  }
  .snowflake:nth-of-type(8){
    left:80%;
    -webkit-animation-delay:1s,0s;
    animation-delay:1s,0s}
    
  .snowflake:nth-of-type(9){
    left:90%;
    -webkit-animation-delay:3s,1.5s;
    animation-delay:3s,1.5s
  }

</style>